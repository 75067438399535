'use client';
import Loading from '@/components/Loading';

const Page = () => (
    <div className='w-full h-[100vh] flex justify-center items-center'>
        <Loading forceLoading={true} className='!static !bg-transparent' />
    </div>
);

export default Page;
